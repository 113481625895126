// extracted by mini-css-extract-plugin
export const background = "three-module--background--253KY";
export const wrapper = "three-module--wrapper--1w8N-";
export const layout = "three-module--layout--LfaSX";
export const left = "three-module--left--2ZnlT";
export const title = "three-module--title--3QxK0";
export const hero = "three-module--hero--UUEcK";
export const table = "three-module--table--AE_sh";
export const tableCellLabel = "three-module--tableCellLabel--mxexf";
export const featuredImage = "three-module--featuredImage--1Bmlw";
export const gallery = "three-module--gallery--sdX_n";
export const right = "three-module--right--CZf-z";
export const secondaryImage = "three-module--secondaryImage--2cTv8";
export const content = "three-module--content--V3Wbg";
export const summary = "three-module--summary--3uwdk";