// extracted by mini-css-extract-plugin
export const background = "two-module--background--3Yj2e";
export const wrapper = "two-module--wrapper--3jDFn";
export const layout = "two-module--layout--D0NXq";
export const left = "two-module--left--2TgDs";
export const title = "two-module--title--11gpC";
export const hero = "two-module--hero--3FfBZ";
export const table = "two-module--table--wWb95";
export const tableCellLabel = "two-module--tableCellLabel--11CYh";
export const secondaryImage = "two-module--secondaryImage--1q6HZ";
export const content = "two-module--content--1Nfry";
export const summary = "two-module--summary--1S8cm";
export const featuredImage = "two-module--featuredImage--2ozwR";