import React, { useState } from "react";
import { graphql } from "gatsby";
import PageWrapper from "components/PageWrapper";
import _Image from "gatsby-image";
import log from "utils/log";
import RichText from "components/RichText";
import SolidCircularButton from "components/SolidCircularButton";
import * as coreTheme from "@bit/cpinnix.verious.theme";
import * as siteTheme from "theme";
import Player from "react-player";
import { SizeMe as Size } from "react-sizeme";
import * as one from "./one.module.css";
import * as two from "./two.module.css";
import * as three from "./three.module.css";

function Image({ file, fluid }) {
  return <_Image fluid={fluid} />;
}

function Video({ file, className }) {
  const [playing, updatePlaying] = useState(false);
  return (
    <Size>
      {({ size }) => (
        <div
          className="bg-white relative"
          onMouseEnter={() => updatePlaying(true)}
          onMouseLeave={() => updatePlaying(false)}
          onClick={() => updatePlaying(!playing)}
        >
          <div
            className={`absolute top-0 left-0 right-0 bottom-0 grid justify-center items-center content-center bg-black transition-opacity duration-100 ${
              playing ? "opacity-0" : "opacity-50"
            }`}
          >
            <svg
              width="45"
              height="50"
              viewBox="0 0 45 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M44.04 28.52C43.656 29.16 43.08 29.672 42.44 30.056L6.024 49.256C5.448 49.576 4.744 49.704 4.104 49.704C1.8 49.704 0.00800323 47.848 0.00800323 45.608V4.392C0.00800323 2.088 1.864 0.296005 4.104 0.296005C4.872 0.296005 5.576 0.488006 6.216 0.872006L42.632 22.824C44.552 24.04 45.192 26.536 44.04 28.52Z"
                fill="white"
              />
            </svg>
          </div>
          <Player
            playing={playing}
            loop
            url={file.url}
            width={size.width}
            height={(size.width * 360) / 640}
          />
        </div>
      )}
    </Size>
  );
}

export const query = graphql`
  query($id: String!) {
    dog: contentfulAdoptDog(id: { eq: $id }) {
      id
      name
      sex
      weight
      breed
      childFriendly
      cat
      age
      adopted
      houseTrained
      adoptionLink
      description {
        raw
      }
      picture {
        id
        file {
          details {
            image {
              height
              width
            }
          }
        }
        fixed {
          src
        }
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      video {
        id
        file {
          contentType
          url
        }
      }
    }
  }
`;

function present(data) {
  log("[PAGE] data", data);

  const seo = {
    title: data?.dog?.name,
    image: data?.dog?.picture[0]?.fixed?.src,
    description:
      "Nana's Haven in St. Petersburg, FL offers support and services for neglected and abused animals. Learn more about us today.",
  };

  const presentation = {
    seo,
    dog: data.dog,
  };

  log("[PAGE] presentation", presentation);

  return presentation;
}

export default function Dog({ data }) {
  const { dog, seo } = present(data);

  const information = [
    ["Sex", dog.sex],
    ["Weight", dog.weight],
    ["Breed", dog.breed],
    ["Child Friendly", dog.childFriendly],
    ["Cat Friendly", dog.cat],
    ["Age", dog.age],
    ["House Trained", dog.houseTrained],
  ].filter((row) => row[1] && row[1] !== "Unknown");

  return (
    <PageWrapper seo={seo}>
      {dog.picture.length === 1 ? (
        <div className={one.background}>
          <div className={one.wrapper}>
            <div className={one.layout}>
              <div className={one.left}>
                <h1 className={one.title}>{dog.name}</h1>
                <div className={one.content}>
                  {dog.sex && dog.breed && (
                    <p>
                      {dog.sex}, {dog.breed}
                    </p>
                  )}
                  {dog.adoptionLink && (
                    <a href={dog.adoptionLink} target="_blank" rel="noreferrer">
                      <SolidCircularButton
                        theme={{
                          backgroundColor: siteTheme.button.primary,
                          color: coreTheme.white,
                        }}
                      >
                        Adopt Me
                      </SolidCircularButton>
                    </a>
                  )}
                  {information.length > 0 && (
                    <table className="table-auto w-full my-8">
                      {information.map((row, index) => (
                        <tr key={index}>
                          <td className="border border-gray-300 p-3 font-bold">
                            {row[0]}
                          </td>
                          <td className="border border-gray-300 p-3">
                            {row[1]}
                          </td>
                        </tr>
                      ))}
                    </table>
                  )}
                  {dog.description && (
                    <div>
                      <RichText json={dog.description} />
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={one.featuredImage}>
                  <Image {...dog.picture[0]} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : dog.picture.length === 2 ? (
        <div className={two.background}>
          <div className={two.wrapper}>
            <div className={two.layout}>
              <div className={two.left}>
                <h1 className={two.title}>{dog.name}</h1>
                <div className={two.hero}>
                  {dog.sex && dog.breed && (
                    <p>
                      {dog.sex}, {dog.breed}
                    </p>
                  )}
                  {dog.adoptionLink && (
                    <a href={dog.adoptionLink} target="_blank" rel="noreferrer">
                      <SolidCircularButton
                        theme={{
                          backgroundColor: siteTheme.button.primary,
                          color: coreTheme.white,
                        }}
                      >
                        Adopt Me
                      </SolidCircularButton>
                    </a>
                  )}
                </div>
                <div className={two.secondaryImage}>
                  <Image {...dog.picture[1]} />
                </div>
                <div className={two.content}>
                  {information.length > 0 && (
                    <table className="table-auto w-full my-8">
                      {information.map((row, index) => (
                        <tr key={index}>
                          <td className="border border-gray-300 p-3 font-bold">
                            {row[0]}
                          </td>
                          <td className="border border-gray-300 p-3">
                            {row[1]}
                          </td>
                        </tr>
                      ))}
                    </table>
                  )}
                  {dog.description && (
                    <div className={two.summary}>
                      <RichText json={dog.description} />
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={two.featuredImage}>
                  <Image {...dog.picture[0]} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={three.background}>
          <div className={three.wrapper}>
            <div className={three.layout}>
              <div className={three.left}>
                <h1 className={three.title}>{dog.name}</h1>
                <div className={three.hero}>
                  {dog.sex && dog.breed && (
                    <p>
                      {dog.sex}, {dog.breed}
                    </p>
                  )}
                  {dog.adoptionLink && (
                    <a href={dog.adoptionLink} target="_blank" rel="noreferrer">
                      <SolidCircularButton
                        theme={{
                          backgroundColor: siteTheme.button.primary,
                          color: coreTheme.white,
                        }}
                      >
                        Adopt Me
                      </SolidCircularButton>
                    </a>
                  )}
                </div>
                <div className={three.secondaryImage}>
                  <Image {...dog.picture[1]} />
                </div>
                <div className={three.content}>
                  {information.length > 0 && (
                    <table className="table-auto w-full my-8">
                      {information.map((row, index) => (
                        <tr key={index}>
                          <td className="border border-gray-300 p-3 font-bold">
                            {row[0]}
                          </td>
                          <td className="border border-gray-300 p-3">
                            {row[1]}
                          </td>
                        </tr>
                      ))}
                    </table>
                  )}
                  {dog.description && (
                    <div className={three.summary}>
                      <RichText json={dog.description} />
                    </div>
                  )}
                </div>
              </div>
              <div className={three.right}>
                <div className={three.featuredImage}>
                  <Image {...dog.picture[0]} />
                </div>
              </div>
              <div className={three.gallery}>
                {dog.picture.slice(2).map((image) => (
                  <Image key={image.id} {...image} />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {dog.video && (
        <div className="bg-gray-200 py-24">
          <div className="container mx-auto px-8 md:px-16">
            {dog.video.map((video) => (
              <Video {...video} className="w-full" />
            ))}
          </div>
        </div>
      )}
    </PageWrapper>
  );
}
